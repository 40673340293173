import { ReactNode } from "react";
import { cn } from "../../utils/cn";

type SectionContainerProps = {
  children: ReactNode;
  fullWidth?: boolean;
  className?: string;
};

export default function SectionContainer({
  children,
  fullWidth = false,
  className,
}: SectionContainerProps) {
  return (
    <div className="relative">
      <div
        className={cn(
          "mx-auto",
          fullWidth ? "w-full" : "max-w-5xl px-4 md:px-6 lg:px-0",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
}
