import Sectional from "./CardComponents/ListCard";
import { FaDollarSign, FaLink, FaLeaf } from "react-icons/fa";

const data = [
  {
    icon: <FaDollarSign className="h-6 w-6 text-teal-600" />,
    text: "Qualify for sustainable finance",
  },
  {
    icon: <FaLink className="h-6 w-6 text-teal-600" />,
    text: "Protect supply chains from climate risk",
  },
  {
    icon: <FaLeaf className="h-6 w-6 text-teal-600" />,
    text: "Partner with stakeholders for faster decarbonization",
  },
];

export default function ListCardSection() {
  return (
    <div className="flex flex-col items-center">
      <Sectional data={data} />
    </div>
  );
}
