import PhotoCard from "./CardComponents/PhotoCard";

export default function MeetTheFounders() {
  return (
    <section className="bg-background py-12 px-4 md:px-8 lg:px-16">
      <h2 className="text-center text-3xl md:text-4xl font-bold py-8 text-text">
        Meet the Founders
      </h2>

      <div className="flex flex-col md:flex-row items-center justify-center gap-8">
        <PhotoCard
          imageUrl="/assets/users/rajeev.jpg"
          name="Rajeev Sinha"
          designation="Co-Founder, CEO"
          details="30+ years of global leadership experience in supply chain management, apparel manufacturing, and exports to the EU. Global consulting experience in ERP systems and program management for manufacturing and retail verticals at NTT DATA, IBM & PWC."
        />
        <PhotoCard
          imageUrl="/assets/users/vivek.jpg"
          name="Vivek Mehra"
          designation="CSO & Co-Founder"
          details="27+ years of global technology leadership in enterprise IT strategy, systems integration, and emerging technologies: AI/ML, Blockchain, IoT. Have built and led technology teams in the US, Asia, EU, and India at NTT DATA and startups for multiple verticals: financial services, retail, manufacturing, hospitality, and pharma."
        />
      </div>
    </section>
  );
}
