import { cn } from "../../utils/cn";

type RequestDemoButtonProps = {
  title?: "Request a demo" | "Schedule a call";
  className?: string;
};

export default function RequestDemoButton({
  title = "Request a demo",
  className,
}: RequestDemoButtonProps) {
  return (
    <button
      className={cn(
        "px-4 py-2 bg-primary text-white font-semibold rounded-full hover:bg-primary/90 transition duration-200",
        className,
      )}
    >
      {title}
    </button>
  );
}
