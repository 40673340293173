import { OgEmailAddress } from "../../constants/SocialConstants";
import ContactUsFormView from "./ContactUsFormView";

// Define types for location data
type Location = {
  country: string;
  address: string;
};

// Create a locations array for the location information
const locations: Location[] = [
  {
    country: "United States of America",
    address: "Main Street, Westford Massachusetts, USA 01886",
  },
  {
    country: "India",
    address: "B19, Sec 54, Suncity, Golf Course Road, Gurugram, Haryana 122002",
  },
  {
    country: "United Arab Emirates",
    address: "Villa 17a, Street 55a, Mirdif, Dubai",
  },
];

export default function LearnMoreSection() {
  return (
    <div className="flex flex-col md:flex-row items-center justify-between bg-background text-text p-8 lg:p-12 px-4">
      {/* Left Section: Contact Information */}
      <div className="w-full md:w-1/2 mb-8 md:mb-0 md:max-w-sm">
        <h2 className="text-4xl font-bold mb-4">Want to learn more?</h2>
        <p className="text-lg text-text-muted mb-8">
          Please fill in your contact details and we'll be in touch
        </p>

        {/* Locations */}
        <div className="space-y-6">
          {locations.map((location, index) => (
            <div key={index}>
              <h3 className="font-semibold capitalize text-text">
                {location.country}
              </h3>
              <p className="text-text-muted">{location.address}</p>
            </div>
          ))}
        </div>

        {/* Contact Email */}
        <div className="mt-8">
          <h3 className="font-semibold capitalize text-text">Get in Touch</h3>
          <a href={`mailto:${OgEmailAddress}`} className="text-primary">
            {OgEmailAddress}
          </a>
        </div>
      </div>

      <ContactUsFormView className="w-full md:w-1/2 bg-surface text-text p-8 rounded-lg shadow-lg" />
    </div>
  );
}
