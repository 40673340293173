import React, { useState } from "react";
import ThemeToggleButton from "../buttons/ThemeToggleButton";
import CallToActionButton from "../buttons/CallToActionButton";
import { FaBars, FaTimes } from "react-icons/fa";

const NavLinks = [
  { name: "Solutions", url: "#" },
  { name: "Media", url: "#" },
  { name: "About Us", url: "#" },
] as const;

export default function Navbar() {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const closeMenuOnClickOutside = (event: React.MouseEvent) => {
    event.stopPropagation();
    setMenuOpen(false);
  };

  return (
    <nav className="fixed top-0 left-0 w-full z-50 px-6 py-4 transition-all duration-300 bg-surface shadow-md">
      <div className="flex justify-between items-center max-w-7xl mx-auto">
        {/* Left: Logo (Mobile: Left side) */}
        <div className="flex items-center space-x-4">
          {/* Logo */}
          <div className="text-2xl font-bold text-primary">
            <img alt="Company name" src="/logo192.png" className="h-9" />
          </div>
        </div>

        {/* Right: Hamburger Menu (Mobile: Right side) */}
        <div className="flex items-center md:hidden space-x-4">
          <button
            onClick={() => setMenuOpen(true)}
            className="text-text hover:text-primary"
            aria-label="Open Menu"
          >
            <FaBars className="w-6 h-6" />
          </button>
        </div>

        {/* Center Navigation Links (visible on desktop only) */}
        <div className="hidden md:flex space-x-6">
          {NavLinks.map((link, index) => (
            <a
              key={index}
              href={link.url}
              className="text-text hover:text-primary transition duration-150 ease-in-out"
            >
              {link.name}
            </a>
          ))}
        </div>

        {/* Right-side Buttons (Desktop) */}
        <div className="hidden md:flex items-center space-x-4">
          <CallToActionButton />
          <ThemeToggleButton />
        </div>

        {/* Mobile Menu Overlay */}
        {menuOpen && (
          <>
            {/* Backdrop that closes the overlay on click */}
            <div
              className="fixed inset-0 bg-black/20 z-40"
              onClick={closeMenuOnClickOutside}
            />

            {/* Mobile Menu Content */}
            <div className="fixed top-0 left-0 right-0 mt-16 bg-surface/80 backdrop-blur-md p-4 z-50">
              {/* Close Button */}
              <button
                onClick={() => setMenuOpen(false)}
                className="absolute top-4 right-6 text-text hover:text-primary"
                aria-label="Close Menu"
              >
                <FaTimes className="w-6 h-6" />
              </button>

              {/* Horizontal alignment for Request Demo and Theme Toggle buttons */}
              <div className="flex justify-center mt-6 space-x-4">
                <CallToActionButton />
              </div>

              <br></br>

              {/* Navigation Links in Overlay */}
              <div className="flex flex-col items-center space-y-6">
                {NavLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.url}
                    onClick={() => setMenuOpen(false)} // close overlay on click
                    className="text-2xl text-text hover:text-primary transition duration-150 ease-in-out"
                  >
                    {link.name}
                  </a>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </nav>
  );
}
