import { cn } from "../../utils/cn";
import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

type ContactUsFormViewProps = {
  className?: string;
};

export default function ContactUsFormView({
  className,
}: ContactUsFormViewProps) {
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    areaOfInterest: "",
    message: "",
  });
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);

  // handler for form input changes
  const handleChange = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
  ) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };

  // validate email to ensure it's professional
  const isProfessionalEmail = (email: string): boolean => {
    const professionalDomains =
      /@(?!gmail\.com|yahoo\.com|outlook\.com|hotmail\.com|icloud\.com|aol\.com)\w+\.\w+$/;
    return professionalDomains.test(email);
  };

  // handle form submit
  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setError(null);
    setSuccess(false);

    if (!isProfessionalEmail(formData.email)) {
      setError(`Please use a professional email address.`);
      return;
    }

    if (!formData.areaOfInterest) {
      setError(`Please select an area of interest.`);
      return;
    }

    // TODO: handle email message send
    // setSuccess(true);
  };

  return (
    <>
      {/* Right Section: Contact Form */}
      <div
        className={cn(
          "w-full lg:w-1/2 bg-surface text-text p-8 rounded-lg shadow-lg",
          className,
        )}
      >
        <h3 className="text-2xl font-bold mb-6">Send us a message</h3>

        {error && <p className="text-red-500 mb-4">{error}</p>}
        {success && (
          <p className="text-green-500 mb-4">Message sent successfully!</p>
        )}

        {/* Form */}
        <form onSubmit={handleFormSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-text-muted"
            >
              Email<span className="text-red-500">*</span>
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 p-2 w-full bg-surface text-text border border-text-muted rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="firstName"
              className="block text-sm font-medium text-text-muted"
            >
              First name
            </label>
            <input
              type="text"
              id="firstName"
              className="mt-1 p-2 w-full bg-surface text-text border border-text-muted rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="lastName"
              className="block text-sm font-medium text-text-muted"
            >
              Last name
            </label>
            <input
              type="text"
              id="lastName"
              className="mt-1 p-2 w-full bg-surface text-text border border-text-muted rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>

          {/* Area of Interest Dropdown */}
          <div className="mb-4">
            <label
              htmlFor="areaOfInterest"
              className="block text-sm font-medium text-text-muted"
            >
              Area of Interest<span className="text-red-500">*</span>
            </label>
            <div className="relative">
              <select
                id="areaOfInterest"
                required
                className="mt-1 p-2 w-full bg-surface text-text border border-text-muted rounded-md focus:outline-none focus:ring-2 focus:ring-primary appearance-none"
                value={formData.areaOfInterest}
                onChange={handleChange}
              >
                <option value="">Please choose an option</option>
                <option value="CBAM">CBAM</option>
                <option value="BRSR">BRSR</option>
                <option value="CDP">CDP</option>
                <option value="GRI">GRI</option>
                <option value="ECOVADIS">ECOVADIS</option>
                <option value="CSRD">CSRD</option>
                <option value="SASB">SASB</option>
                <option value="ESG">ESG</option>
                <option value="Other">Other</option>
              </select>
              <div className="absolute top-1/2 right-4 transform -translate-y-1/2 flex items-center pointer-events-none text-text-muted">
                <ChevronDownIcon className="w-5 h-5" />
              </div>
            </div>
          </div>

          <div className="mb-6">
            <label
              htmlFor="message"
              className="block text-sm font-medium text-text-muted"
            >
              Message
            </label>
            <textarea
              id="message"
              className="mt-1 p-2 w-full bg-surface text-text border border-text-muted rounded-md focus:outline-none focus:ring-2 focus:ring-primary"
              rows={4}
              value={formData.message}
              onChange={handleChange}
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full bg-primary text-surface p-2 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
}
