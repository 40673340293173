import React from "react";
import {
  SocialLink,
  OrganizationName,
  FooterMissionStatement,
} from "../../constants/SocialConstants";
import { CompanyLinks } from "../../constants/CompanyLinks";
import { FaFacebook } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const NavigationLinks = [
  { name: "About", url: CompanyLinks.AboutUs },
  { name: "Careers", url: CompanyLinks.Careers },
  { name: "Blog", url: CompanyLinks.Blog },
  { name: "Contact Us", url: CompanyLinks.ContactUs },
] as const;

export default function Footer() {
  return (
    <footer className="mt-12 pt-8 border-t border-text-muted/20 sm:mt-8 lg:mt-12">
      {/* top half with company info and links */}
      <div className="grid grid-cols-1 gap-12 items-start sm:grid-cols-2">
        <div className="space-y-2 flex flex-col justify-center items-center sm:items-start">
          <img alt="Company name" src="/logo192.png" className="h-9" />
          <p className="text-balance text-sm/6 text-text max-w-sm text-center sm:text-left">
            {FooterMissionStatement}
          </p>
        </div>

        {/* navigation links */}
        <div className="flex flex-col justify-center items-center gap-4 sm:items-start">
          {NavigationLinks.map(({ name, url }, index) => (
            <a
              href={url}
              key={index}
              target="_blank"
              rel="noreferrer"
              className="text-text transition-opacity hover:opacity-80 cursor-pointer"
            >
              {name}
            </a>
          ))}
        </div>
      </div>
      {/* copy right text and social links at bottom */}
      <div className="my-6 border-t border-text-muted/20 pt-4 sm:mt-8 lg:mt-12">
        <div className="w-full flex flex-col justify-center items-center gap-4 sm:flex-row sm:justify-between">
          {/* copyright text */}
          <p className="text-sm text-text/80">
            &copy; {new Date().getFullYear()} {OrganizationName}, All rights
            reserved.
          </p>
          {/* social links */}
          <div className="flex justify-center items-center gap-4">
            <SocialIconLink iconName="facebook" url={SocialLink.Facebook} />
            <SocialIconLink iconName="youtube" url={SocialLink.Youtube} />
            <SocialIconLink iconName="instagram" url={SocialLink.Instagram} />
            <SocialIconLink iconName="linkedin" url={SocialLink.LinkedIn} />
          </div>
        </div>
      </div>
    </footer>
  );
}

type SocialIconButtonProps = {
  iconName: "facebook" | "youtube" | "instagram" | "linkedin";
  url: string;
};

function SocialIconLink({ iconName, url }: SocialIconButtonProps) {
  const renderIconContent = (): React.ReactNode => {
    switch (iconName) {
      case "facebook":
        return <FaFacebook className="h-6 w-6" aria-hidden="true" />;
      case "youtube":
        return <FaYoutube className="h-6 w-6" aria-hidden="true" />;
      case "instagram":
        return <FaInstagram className="h-6 w-6" aria-hidden="true" />;
      case "linkedin":
        return <FaLinkedin className="h-6 w-6" aria-hidden="true" />;
      default:
        return <></>;
    }
  };

  return (
    <a
      href={url}
      className="text-text/80 transition-opacity hover:opacity-80 cursor-pointer"
      target="_blank"
      rel="noreferrer"
    >
      <span className="sr-only">{iconName}</span>
      {renderIconContent()}
    </a>
  );
}
