import { ReactNode } from "react";

type AppContainerProps = {
  children?: ReactNode;
};

export default function AppContainer({ children }: AppContainerProps) {
  return (
    <div className="bg-background">
      {/* scrollbar */}
      <div className="overflow-y-scroll scrollbar-thin scrollbar-thumb-primary scrollbar-track-background h-screen">
        {/* main padding and spacing */}
        <div className="relative">
          {/* main content goes here */}
          {children}
        </div>
      </div>
    </div>
  );
}
