type HeroOverlaySectionProps = {
  horizontalAlignment?: "left" | "right"; // Accepting positionClass as a prop
};

export default function HeroOverlaySection({
  horizontalAlignment = "right",
}: HeroOverlaySectionProps) {
  const buttons = [
    { text: "Commodity Traders", link: "#" },
    { text: "Manufacturers", link: "#" },
    { text: "Trade Finance Providers", link: "#" },
  ] as const;

  return (
    <div className="w-full bg-transparent">
      {/* Section Title */}
      <h2 className="text-3xl md:text-4xl font-semibold py-8 text-text px-6 lg:px-8">
        A platform tailored to your industry
      </h2>

      {/* Background Section with Overlay Buttons */}
      <div
        className="relative w-full h-[400px] md:h-[500px] bg-cover bg-center"
        style={{ backgroundImage: "url('/assets/design-tmp/thirdImage.jpg')" }}
      >
        {/* Overlay Buttons */}
        <div
          className={`absolute ${horizontalAlignment === "right" ? "right-8" : "left-8"} top-32 md:top-1/4 flex flex-col space-y-4`}
        >
          {buttons.map((button, index) => (
            <button
              key={index}
              className="bg-[rgba(3,3,3,0.85)] hover:bg-[#ffffff] text-white px-6 py-4 w-72 rounded-lg text-lg font-medium flex items-center justify-between transition duration-300 hover:text-primary"
            >
              {button.text}
              <span className="ml-auto text-xl text-primary transition-transform transform hover:translate-x-1">
                →
              </span>
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
