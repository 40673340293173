import React, { useState } from "react";

interface PhotoCardProps {
  imageUrl: string;
  name: string;
  designation: string;
  details: string;
}

const PhotoCard: React.FC<PhotoCardProps> = ({
  imageUrl,
  name,
  designation,
  details,
}) => {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className="relative w-80 h-96 rounded-lg overflow-hidden shadow-lg transform transition-transform duration-500 ease-in-out hover:scale-105"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {/* Background Image with Zoom Effect */}
      <div
        className={`w-full h-full bg-cover bg-center transition-transform duration-500 ${hovered ? "scale-110" : "scale-100"}`}
        style={{ backgroundImage: `url(${imageUrl})` }}
      />

      {/* Darker Gradient Overlay on Hover */}
      <div
        className={`absolute inset-0 bg-gradient-to-t from-black/80 via-black/50 to-transparent transition-opacity duration-500 ${hovered ? "opacity-90" : "opacity-70"}`}
      />

      {/* Initial Info */}
      <div
        className={`absolute bottom-4 left-4 right-4 text-center text-white transition-all duration-500 ${
          hovered ? "opacity-0 translate-y-[-10px]" : "opacity-100"
        }`}
      >
        <h3 className="text-xl font-semibold">{name}</h3>
        <p className="text-sm text-gray-300">{designation}</p>
      </div>

      {/* Hover Details */}
      <div
        className={`absolute inset-0 flex flex-col justify-center items-center text-white text-center px-6 transition-opacity duration-500 ${
          hovered ? "opacity-100" : "opacity-0"
        }`}
      >
        <h3 className="text-2xl font-semibold mb-2">{name}</h3>
        <p className="text-sm font-medium text-gray-300 mb-4">{designation}</p>
        <p className="text-sm">{details}</p>
      </div>
    </div>
  );
};

export default PhotoCard;
