import { OrganizationName } from "../../constants/SocialConstants";

export default function ClimatePlatformHeroSection() {
  return (
    <div className="bg-background py-16 px-6 lg:px-12">
      <div className="max-w-5xl mx-auto flex flex-col lg:flex-row items-start">
        {/* Left Section */}
        <div className="lg:w-1/2 text-left space-y-4 pr-8">
          <h1 className="text-4xl lg:text-5xl font-extrabold text-text leading-tight">
            The climate platform that puts you in charge
          </h1>
        </div>

        {/* Right Section */}
        <div className="lg:w-1/2 text-left space-y-4">
          <p className="text-lg text-text-muted leading-relaxed">
            Are you ready for rising carbon costs and disclosure requests? The
            economy is rewiring for a net-zero future, and your competitors are
            tapping into low-carbon demand. Get ahead with {OrganizationName}'s
            bulletproof carbon accounting software.
          </p>
        </div>
      </div>

      {/* Cards Section */}
      <div className="mt-10 grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <ClimatePlatformCard
          title="Measure"
          description="Measure emissions with confidence"
          dataTitle="Total Emissions"
          dataValue="137,450"
          dataUnit="Tonnes CO₂e"
        />
        <ClimatePlatformCard
          title="Report"
          description="Report with speed and ease"
          dataTitle="GHG Emissions Inventory"
          hasChart
        />
        <ClimatePlatformCard
          title="Transition"
          description="Set targets for the transition"
          dataTitle="Steel (tCO₂e/t)"
          hasLineChart
        />
      </div>
    </div>
  );
}

type ClimatePlatformCardProps = {
  title: string;
  description: string;
  dataTitle: string;
  dataValue?: string;
  dataUnit?: string;
  hasChart?: boolean;
  hasLineChart?: boolean;
};

function ClimatePlatformCard({
  title,
  description,
  dataTitle,
  dataValue,
  dataUnit,
  hasChart,
  hasLineChart,
}: ClimatePlatformCardProps) {
  return (
    <div className="bg-surface p-6 rounded-lg shadow-md space-y-4">
      <div className="flex justify-between items-center">
        <div>
          <h3 className="text-xl font-semibold text-text">{title}</h3>
          <p className="text-text-muted">{description}</p>
        </div>
        <span className="text-text-muted text-2xl">→</span>
      </div>

      <div className="bg-background p-4 rounded-md">
        <h4 className="text-sm font-semibold text-text-muted">{dataTitle}</h4>
        {dataValue && (
          <p className="text-3xl font-bold text-primary">
            {dataValue}
            <span className="text-sm font-medium text-text-muted ml-2">
              {dataUnit}
            </span>
          </p>
        )}
        {hasChart && (
          <div className="h-20 mt-4 bg-gray-300 rounded-md flex items-end">
            <div className="h-12 w-1/3 bg-primary rounded-t-md"></div>
            <div className="h-8 w-1/3 bg-gray-400 mx-2 rounded-t-md"></div>
            <div className="h-10 w-1/3 bg-gray-400 rounded-t-md"></div>
          </div>
        )}
        {hasLineChart && (
          <div className="h-20 mt-4 flex items-center">
            <svg className="w-full h-full text-primary" viewBox="0 0 100 30">
              <polyline
                fill="none"
                stroke="#CCCCCC"
                strokeWidth="2"
                points="0,15 20,10 40,12 60,8 80,10 100,5"
              />
              <polyline
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                points="0,25 20,20 40,22 60,18 80,20 100,15"
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}
