import AppContainer from "../components/containers/AppContainer";
import FaqsSection from "../components/faqs/FaqsSection";
import { ThemeProvider } from "../hooks/ThemeContext";
import SectionContainer from "../components/containers/SectionContainer";
import Footer from "../components/footer/Footer";
import LearnMoreSection from "../components/contact-us/LearnMoreSection";
import Navbar from "../components/navbar/NavBar";
import HeroSectionOne from "../components/sections/HeroSectionOne";
import ClimatePlatformHeroSection from "../components/sections/ClimateProductHeroSection";
import HeroSectionTwo from "../components/sections/HeroSectionTwo";
import BackedBy from "../components/sections/BackedBy";
import TestimonalCardSection from "../components/sections/TestimonialCardSection";
import ListCardSection from "../components/sections/ListCardSection";
import HeroOverlaySection from "../components/sections/HeroOverlaySection";
import MeetTheFounders from "../components/sections/MeettheFounders";

export default function HomeLandingPage() {
  return (
    <ThemeProvider>
      <AppContainer>
        <Navbar />

        <SectionContainer fullWidth className="mt-16 pb-8">
          <HeroSectionOne />
        </SectionContainer>

        <SectionContainer className="py-8">
          <HeroSectionTwo />
        </SectionContainer>

        <SectionContainer fullWidth>
          <HeroOverlaySection horizontalAlignment="right" />
        </SectionContainer>

        <SectionContainer className="py-8">
          <ClimatePlatformHeroSection />
        </SectionContainer>

        <SectionContainer className="py-8">
          <FaqsSection />
        </SectionContainer>

        <SectionContainer>
          <MeetTheFounders />
          <BackedBy />
        </SectionContainer>

        <SectionContainer fullWidth>
          <TestimonalCardSection />
          <ListCardSection />
        </SectionContainer>

        <SectionContainer className="py-8">
          <LearnMoreSection />
        </SectionContainer>

        <SectionContainer className="pb-8">
          <Footer />
        </SectionContainer>
      </AppContainer>
    </ThemeProvider>
  );
}
