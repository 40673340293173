import React from "react";

type CardData = {
  icon: React.ReactNode;
  text: string;
};

interface SectionalProps {
  data: CardData[];
}

const Sectional: React.FC<SectionalProps> = ({ data }) => {
  return (
    <div className="flex flex-col sm:grid sm:grid-cols-2 md:grid-cols-3 gap-6 sm:gap-8">
      {data.map((item, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col items-center text-center max-w-[180px] w-full mx-auto">
            <div className="flex items-center justify-center w-12 h-12 bg-teal-100 rounded-full border border-teal-300">
              {item.icon}
            </div>
            <p className="mt-2 text-sm text-text break-words">{item.text}</p>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Sectional;
